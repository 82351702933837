import { useEffect, useState, Suspense, memo } from "react";
import { Canvas } from '@react-three/fiber';
import { useSpring, animated } from '@react-spring/three';
import { OrbitControls, useGLTF, Decal, useTexture } from '@react-three/drei';
import Model from './Model';

const CanvasContainer = () => {
  const [model1Position, setModel1Position] = useState([-1, 1, 2]);
  const [model2Position, setModel2Position] = useState([5, -0.1, 2]);
  const [scrollY, setScrollY] = useState(0);
  const isInRange = scrollY >= 1000 && scrollY <= 3500;
  const rotationValue = isInRange ? [scrollY / 2000, 3, 0] : [1.2, 3, 0];

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setModel1Position([-0.5, 0.2, 1.2]); // Adjust positions as needed
      setModel2Position([3.8, -0.35, 3]); // Adjust positions as needed
    } else {
      setModel1Position([-1, 1, 2]);
      setModel2Position([5, -0.1, 2]);
    }
  }, []);

  const model1Spring = useSpring({
    rotation: rotationValue,
  });

  const model2Spring = useSpring({
    rotation: rotationValue,
  });

  return (
    <Canvas style={{ top: 0, left: 0, width: '100%', height: "450px" }} alpha>
      <Suspense fallback={null}>
        <ambientLight intensity={2} color={"white"} />
        <directionalLight intensity={4} angle={4} penumbra={1} castShadow />
        <spotLight intensity={1} angle={1} penumbra={1} castShadow />

        <animated.group rotation={model1Spring.rotation}>
          <Model position={model1Position} scale={10.2} rotation={[0.3, 2, -1.4]} color="white" />
        </animated.group>
        <animated.group rotation={model2Spring.rotation}>
          <Model position={model2Position} scale={19.2} rotation={[0.3, 2.1, -1.4]} />
        </animated.group>
      </Suspense>
    </Canvas>
  );
}

export default memo(CanvasContainer);