const VideoContainer  = ({language}) => { 
    
    return(
        <div className='maxContain'>
        <img src='/bxhaled-back.png' style={{width:"100%", position:"absolute", zIndex:"1"}}/>
        <div className='max-container'>
            <div className='video-info' style={{position:"relative", zIndex:"1"}}>
                <h2 style={{color:"white"}}>{language('home.video.title')}</h2>
                <p style={{color:"white", marginTop:"0px"}}>
                {language('home.video.description')}
                </p>
            </div>
            <div className='video-container' style={{position:"relative", zIndex:"1"}}>
            <video src="/productlistingvid.mov" controls style={{width:"100%", zIndex:"2"}}poster="/headerPic.png">

            </video>
            </div>
        </div>


        </div>
    );

}

export default VideoContainer;