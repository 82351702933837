import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import useLanguageSwitcher from './UseLanguageSwitcher';

function TopBar({ setChosenLanguage, currentLang, currentCountry, setCurrentCountry, language }) {
  const [selectedCountry, setSelectedCountry] = useState('SE');
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [isCountryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [isScreenSmall, setIsScreenSmall] = useState(false);

  const { changeLanguageAndRedirect } = useLanguageSwitcher();
  const navigate = useNavigate();

  console.log(currentLang)
  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 844);
    };

    handleResize(); // Check on mount
    window.addEventListener('resize', handleResize); // Add resize event listener

    return () => window.removeEventListener('resize', handleResize); // Clean up
  }, []);

  useEffect(() => {
    setSelectedLanguage(currentLang === 'sv' ? 'Svenska' : 'English');
  }, [currentLang]);

  const handleCountryChange = (country) => {
    const langCode = country === 'SE' ? 'sv' : 'en';
    const languageName = country === 'SE' ? 'Svenska' : 'English';

    changeLanguageAndRedirect(langCode);
    setSelectedLanguage(languageName);
    setCurrentCountry(country);
    setCountryDropdownOpen(false);
  };

  const handleLanguageChange = (language, code) => {
    setSelectedLanguage(language);
    changeLanguageAndRedirect(code);
    setLanguageDropdownOpen(false);
  };

  return (
    <>
      {!isScreenSmall && (
        <div style={styles.topBarContainer}>
          <div style={styles.titleContainer}>
            <span>{language('topbar.title')}</span>
          </div>

          <div style={styles.selectorsContainer}>
            {/* Country Selector */}
            <div style={styles.dropdownContainer}>
              <div style={styles.dropdownHeader} onClick={() => setCountryDropdownOpen(prev => !prev)}>
                {currentCountry && (
                  <img 
                    src={`https://flagcdn.com/${currentCountry.toLowerCase()}.svg`} 
                    alt="flag" 
                    style={styles.flagImage} 
                  />
                )}
                <span>{currentCountry}</span>
                <span style={styles.dropdownArrow}>{isCountryDropdownOpen ? '▲' : '▼'}</span>
              </div>
              {isCountryDropdownOpen && (
                <ul style={styles.dropdownMenu}>
                  {['SE', 'US', 'DE', 'ROW'].map(country => (
                    <li key={country} onClick={() => handleCountryChange(country)} style={styles.dropdownItem}>
                      {country}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Language Selector */}
            <div style={styles.dropdownContainer}>
  <div 
    style={styles.dropdownHeader} 
    onClick={() => setLanguageDropdownOpen(prev => !prev)}
  >

        <span>{selectedLanguage}</span>
        {currentCountry === 'SE' && (
      <>
        <span style={styles.dropdownArrow}>
          {isLanguageDropdownOpen ? '▲' : '▼'}
        </span>
      </>
    )}
  </div>
  {isLanguageDropdownOpen && (
    <ul style={styles.dropdownMenu}>
      <li onClick={() => handleLanguageChange('English', 'EN')} style={styles.dropdownItem}>English</li>
      <li onClick={() => handleLanguageChange('Svenska', 'SV')} style={styles.dropdownItem}>Svenska</li>
    </ul>
  )}
</div>

          </div>
        </div>
      )}
    </>
  );
}

const styles = {
  topBarContainer: {
    backgroundColor: '#15AC9C',
    color: 'white',
    padding: '20px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: '0px',
  },
  titleContainer: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
  },
  selectorsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    position: 'absolute',
    right: '20px',
  },
  dropdownContainer: {
    position: 'relative',
  },
  dropdownHeader: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  flagImage: {
    width: '20px',
    marginRight: '5px',
  },
  dropdownArrow: {
    marginLeft: '5px',
  },
  dropdownMenu: {
    position: 'absolute',
    top: '30px',
    backgroundColor: 'white',
    color: 'black',
    listStyleType: 'none',
    padding: '10px',
    margin: 0,
    borderRadius: '5px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 3,
  },
  dropdownItem: {
    cursor: 'pointer',
    padding: '5px 10px',
  },
};

export default TopBar;
