        
        
const IntroContainer  = ({language}) => { 
   
    return(
        <div className='introContainer'>
            <div className='max-container'>
                <div className='introDivs'>
                    <h2 style={{marginTop:"0px"}}> <a style={{color:"#15A19C"}}> {language('home.descr.sverige')}</a> {language('home.descr.title1')}</h2>
                    <div className="introIntro">
                        <div>
                            <div className="introIntro1" style={{display:"flex", alignItems:"center"}}>
                                <img src='/storage.png' style={{height:"50px", paddingRight:"25px"}}/>
                                <h3 style={{color:"#15A19C", marginBottom:"30px"}}>{language('home.descr.ventilerar')}</h3>
                            </div>
                            <p style={{marginTop:"0px"}}>{language('home.descr.ventilerarInfo')}</p>     
                        </div>
                        <div>
                            <div className="introIntro2" style={{display:"flex", alignItems:"center"}}>
                                <img src='/venti.png' style={{height:"70px", paddingRight:"20px", marginLeft:"-20px", }}/>
                                <h3 style={{color:"#15A19C", marginBottom:"20px"}}>{language('home.descr.forvaring')}</h3>
                            </div>
                            <p style={{marginTop:"0px"}}>{language('home.descr.forvaringInfo')}</p>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    );
}

export default IntroContainer;