import axios from 'axios';

const API_URL = 'https://bxhale-checkout.myshopify.com/api/2023-04/graphql.json'; // Update with your store URL
const ACCESS_TOKEN = 'eb283476f9566c8bb31b163615a8d117'; // Update with your Storefront API access token

const shopifyService = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Shopify-Storefront-Access-Token': ACCESS_TOKEN,
  },
});

export const getProducts = async () => {
  const query = `
    {
      products(first: 10) {
        edges {
          node {
            id
            title
            description
            images(first: 5) {
              edges {
                node {
                  originalSrc
                  altText
                }
              }
            }
            variants(first: 5) {
              edges {
                node {
                  id
                  title
                  priceV2 {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
  const response = await shopifyService.post('', { query });
  return response.data.data.products.edges;
};


export const getProductDetail = async (productId) => {
  const query = `
    {
      product(id: "${productId}") {
        id
        title
        description
        images(first: 16) {
          edges {
            node {
              originalSrc
              altText
            }
          }
        }
        variants(first: 5) {
          edges {
            node {
              id
              title
              priceV2 {
                amount
                currencyCode
              }
              image {
                originalSrc
                altText
              }
            }
          }
        }
      }
    }
  `;
  const response = await shopifyService.post('', { query });
  return response.data.data.product;
};

export const createCheckout = async (lineItems) => {
    const mutation = `
      mutation($lineItems: [CheckoutLineItemInput!]!) {
        checkoutCreate(input: { lineItems: $lineItems }) {
          checkout {
            id
            webUrl
          }
        }
      }
    `;
    const variables = { lineItems };
    const response = await shopifyService.post('', { query: mutation, variables });
    console.log('GraphQL Response:', response.data, variables);
    
    if (response.data.errors) {
      console.error('GraphQL Errors:', response.data.errors);
    }
    return response.data.data.checkoutCreate.checkout;
  };
  
  export const addLineItemsToCheckout = async (checkoutId, lineItems) => {
    const mutation = `
      mutation($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
        checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
          checkout {
            id
            webUrl
          }
        }
      }
    `;
    const variables = { checkoutId, lineItems };
    const response = await shopifyService.post('', { query: mutation, variables });
    return response.data.data.checkoutLineItemsAdd.checkout;
  };