import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three';
import { useEffect } from 'react';
export default function Model(props) {
    const { nodes, materials } = useGLTF('/lock.glb')

    // Create a cloned material if color prop is provided
    const material = props.color ? materials['Plastic.003'].clone() : materials['Plastic.003'];

    useEffect(() => {
        if (props.color) {
            material.color = new THREE.Color(props.color);
        }
    }, [props.color, material]);

    return (
        <group {...props} dispose={null}>
            <group
                position={[-0.002, 0.251, -0.02]}
                rotation={[Math.PI / 2, 0, 2.118]}
                scale={[0.013, 0.013, 0.012]}
            >
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder023.geometry}
                    material={material}
                />
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder023_1.geometry}
                    material={materials['Car Textured Rough Plastic.007']}
                />
                <group
                    position={[-0.066, 0.029, -0.126]}
                    rotation={[0, 0, 1.444]}
                    scale={[0.003, 0.004, 0.004]}
                >
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube001.geometry}
                        material={material}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube001_1.geometry}
                        material={materials['Car Textured Rough Plastic.009']}
                    />
                </group>
                <group
                    position={[-0.004, -0.115, -0.126]}
                    rotation={[0, 0, 3.031]}
                    scale={[0.003, 0.004, 0.004]}
                >
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube001.geometry}
                        material={material}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube001_1.geometry}
                        material={materials['Car Textured Rough Plastic.009']}
                    />
                </group>
                <group
                    position={[0.122, -0.014, -0.126]}
                    rotation={[0, 0, -1.713]}
                    scale={[0.003, 0.004, 0.004]}
                >
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube001.geometry}
                        material={material}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube001_1.geometry}
                        material={materials['Car Textured Rough Plastic.009']}
                    />
                </group>
                <group
                    position={[0.045, 0.116, -0.126]}
                    rotation={[0, 0, 0.038]}
                    scale={[0.003, 0.004, 0.004]}
                >
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube001.geometry}
                        material={material}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube001_1.geometry}
                        material={materials['Car Textured Rough Plastic.009']}
                    />
                </group>
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder014.geometry}
                    material={materials['Car Textured Rough Plastic.005']}
                    position={[0.001, 0, -1.953]}
                    scale={[0.01, 0.01, 0.002]}
                >
                    <group
                        position={[-0.052, 2.171, 961.801]}
                        rotation={[Math.PI / 2, 0, 0]}
                        scale={[0.002, 0.011, 0.002]}
                    >
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.Cylinder021_1.geometry}
                            material={material}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.Cylinder021_2.geometry}
                            material={materials['Material.001']}
                        />
                    </group>
                </mesh>
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder016.geometry}
                    material={material}
                    position={[0.05, 0, 0.037]}
                    scale={[0.025, 0.025, 0.024]}
                >
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube020.geometry}
                        material={material}
                        position={[-1.826, 0.07, -0.971]}
                        rotation={[1.581, 0.005, 1.325]}
                        scale={[0.002, 0, 0]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube021.geometry}
                        material={material}
                        position={[-2.487, 0.07, -1.382]}
                        rotation={[1.568, -0.008, 2.895]}
                        scale={[0.002, 0, 0]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube022.geometry}
                        material={material}
                        position={[-2.091, 0.07, -2.069]}
                        rotation={[1.581, 0.005, -1.817]}
                        scale={[0.002, 0, 0]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube023.geometry}
                        material={material}
                        position={[-1.429, 0.07, -1.658]}
                        rotation={[1.568, -0.008, -0.246]}
                        scale={[0.002, 0, 0]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder021.geometry}
                        material={materials['Material.006']}
                        position={[-1.958, 0.27, -1.514]}
                        rotation={[Math.PI / 2, 0, 0]}
                        scale={0.001}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder024.geometry}
                        material={material}
                        position={[-1.958, -0.153, -1.52]}
                        rotation={[1.551, -0.007, 2.33]}
                        scale={-0.003}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Cylinder026.geometry}
                        material={materials['Material.007']}
                        position={[-1.978, 0.25, -1.52]}
                        rotation={[-1.568, -0.008, 0.246]}
                        scale={[0.013, 0.014, 0.011]}
                    />
                </mesh>
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder025.geometry}
                    material={materials['Car Textured Rough Plastic.005']}
                    position={[0.05, 0, 0]}
                    scale={[0.026, 0.026, 0.024]}
                />
            </group>
        </group>
    )
}


useGLTF.preload('/lock.glb')
