import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          readMore: "Read more",
          buyNow: "BUY NOW",
          close:"Close",
          thankYou: "Thank you",
          wrongurl: {
            title: 'Wrong URL',
            description: 'The URL couldn`t be found :(',
          },
          topbar:{
            title:'NOW ON AMAZON'
          },
          navbar:{
            home: "Home Page",
            orders: "Special Orders",
            contact: "Contact Us"

          },
          product:{
            Black: "Black",
            Red: "Cerise Red",
            White:"White",
            Blue: "Aqua Blue"

          },
          home:{
            header: {
              intro: "The bottle that stays fresh",
              description: "Innovative Cap - No more smelly bottles or lost lids!"
            },
            descr:{
              title1:"innovation for Longer Lasting Bottles",
              title2:"patent that prolongs the life of your bottle",
              sverige: "Swedish",
              ventilerar: "Ventilates",
              ventilerarInfo: "Breathable Cap - no more smelly bottles",
              forvaring: "Smart storage",
              forvaringInfo: "Storage Solution - Save space and keep track of the cap"
            },
            startTitle:{
              title: "Store bottles in a smart way",
              description: "Without risk of losing or mixing the lids, to save storage and keeping it organized. "
            },
            productpage: {
              title: "Our products"
            },
            video: {
              title: "The innovation behind our bottle",
              description: "Our patented cap keeps your bottle fresh and prevents lost or mixed-up caps. Watch the video to learn more!"
            },
            animation: {
              title: "The secrets is in our lids",
              description: "With the help of our patented design you can place the lid on the bottle upside down. Or as we like to say:"
            },
            infobox: {
              title: "We care us about your and the earth's health",
              description: "Our bottles are produced with focus on the environment and health",
              bpa: {
                title: "BPA-free",
                description: "Our bottles are produced with 100% BPA-free plastic"
              },
              bioplast: {
                title: "Bio-plastic",
                description: "The body of the bottle is made out of bio-plastic, which consists of 100% sugarcains."
              },
              sun: {
                title: "Sun energy",
                description: "Produced through 100% renewable energy, like sun- and och vind-power."
              }
            },
            desc: {
              volym : "Volume",
              volym2 : "750ml",
              material : "Material",
              material2: "Bio based plastic",
              dimensioner : "Dimensions",
              dimensioner2 : "24cm x 7.5cm x 7.5cm",
              annat : "Other",
              annat2: "BPA-free and leak proof"

            },
            story: {
              title: "Our story",
              description: "We are a family business run by a father and son. Our roots are in a small town, and the idea was born on a warm June day on the way to a soccer practice.",
              description2: "We love supporting other small businesses and organizations! If you're interested in our bottles for your business or event, reach out to us. We look forward to collaborating and helping you achieve a healthier and more convenient everyday life."
            }
          },
          contact:{
            title: "Say hi and tell us how we can help you",
            description: "And we will get back to you as soon as possible",
            name: "Name",
            nameExample: "Your name...",
            email: "Email",
            emailExample: "Example@mail.com",
            message: "Message",
            sendMessage: "Send message",
            sending: "Sending",
            messageExample: "Write your message here...",
            response1Title: "Thank you for contacting us!",
            response1Description: "We've received your message, and will be responding if needed within 72 hours.",
            response2Title: "Woops! Your message couldn't be sent :(",
            response2Description: "Try again, or contact us at abcefde@fasd.com if problem should persist",
            
          },
          orders:{
            title:"Business or corporation?",
            description:"We accept orders from both businesses and organizations, tailored to fit their specific needs.",
            section1Tit:"Custom printing",
            section1Desc:"We offer custom printing on bottles for both businesses and organizations. Our printing services are based locally in Sweden or on-site at our factory, ensuring efficient production times.",
            section2Tit:"Retail",
            section2Desc:"We sell bottles with our own brand to local retailers. Interested in adding our bottles to your assortment?"
          },
          footer:{
            title:"Be the first to know",
            description:"We're still in the startup phase, so lots of exciting news and offers are around the corner. Sign up to be the first to know about exclusive updates!",
            navigation: "Navigation",
            navigationHome: "Home",
            links: "Links",
            contact: "Contact",
            send: "Send",
            sending: "Sending"
          },
          cookies:{
            title:"We value your privacy",
            description:"We use cookies to enchance your browsing experience, such as remembering your preffered language! By clicking 'consent all', you consent to our use of cookies.",
            neka : "Reject All",
            acceptera : "Accept All",
            readMore : "Read more",
            taBort : "Remove All"
          }

        }

        
      },

      sv: {
        
        translation: {
          readMore: "Läs mer",
          buyNow: "KÖP NU",
          close: "Stäng",
          thankYou: "Tack",
          wrongurl: {
            title: 'Fel url',
            description: 'Urlen kunde inte hittas :(',
          },
          topbar:{
            title:'FINNS ATT KÖPA PÅ AMAZON'
          },
          navbar:{
            home: "STARTSIDA",
            orders: "FÖRETAGSBESTÄLLNINGAR",
            contact: "KONTAKTA OSS"
          },
          product:{
            Black: "Svart",
            Red: "Ceriseröd",
            White:"Vit",
            Blue: "Turkosblå"

          },
          home:{
            header: {
              intro: "Flaskan som håller sig fräsch",
              description: "Innovativt lock - inga illaluktande flaskor eller borttappade lock!"
            },
            descr:{
              title1:"innovation för längre livslängd på dina flaskor",
              title2:"patent som förlänger livslängden för dina flaskor",
              sverige: "Svensk",
              ventilerar: "Ventilerar",
              ventilerarInfo: "Ventilerande lock - Inga mer illaluktande flaskor",
              forvaring: "Smart förvaring",
              forvaringInfo: "Förvaringslösning - Spara plats och håll koll på locket "
            },
            startTitle:{
              title: "Förvara flaskor på ett smart sätt",
              description: "Utan risk för borttappade eller ihopblandade lock, för att spara plats och hålla det organiserat."
            },
            productpage: {
              title: "Våra produkter"
            },
            video: {
              title: "Innovationen bakom vår flaska",
              description: "Vårt patenterade lock håller din flaska fräsch och förhindrar att lock försvinner eller blandas ihop. Se videon för att lära dig mer!"
            },
            animation: {
              title: "Hemligheten är vårt unika lock",
              description: "Med hjälp av vår patenterade design kan du fästa locket på flaskan upp och ner. Eller som vi brukar säga:"
            },
            infobox: {
              title: "Varför vår flaska?",
              description: "Våra flaskor är framtagna med fokus på miljö och hälsa",
              bpa: {
                title: "Hygien",
                description: "Vår flaska motverkar dålig lukt, och förhindrar ihopblanding av lock"
              },
              bioplast: {
                title: "Organisation",
                description: "Håll skået rent, montera locket på flaskan efter rengöring"
              },
              sun: {
                title: "MIljövänlig",
                description: "Producerad genom 100% förnybar energi, med en flaskkropp gjord av 100% bioplast"
              }
            },
            desc: {
              volym : "Volym",
              volym2 : "750ml",
              material : "Material",
              material2: "Plast från sockerrör",
              dimensioner : "Dimensioner",
              dimensioner2 : "24cm x 7.5cm x 7.5cm",
              annat : "Annat",
              annat2: "BPA-fri och läckagefri"

            },

            story: {
              title: "Vår story",
              description: "Vi är ett familjeföretag med rötterna i Boden. Företaget drivs av en far och son, och idén föddes en varm junidag på väg till en fotbollsträning. ",
              description2: "Vi älskar att stödja andra småföretag och föreningar! Om du är intresserad av våra flaskor för din verksamhet eller evenemang, hör av dig till oss. Vi ser fram emot att samarbeta och bidra till en friskare och smidigare vardag för er."
            }
          },
          contact:{
            title: "Säg hej och berätta hur vi kan hjälpa dig",
            description: "Och vi kommer återkomma så snart som möjligt",
            name: "Namn",
            nameExample: "Ditt namn...",
            email: "Email",
            emailExample: "Exempel@mail.se",
            message: "Meddelande",
            messageExample: "Skriv ditt meddelande här",
            sendMessage: "Skicka svar",
            sending: "Skickar",
            response1Title: "Tack för att du kontaktar oss!",
            response1Description: "Vi har tagit emot ditt meddelande. Om det kräver ett svar återkommer vi till dig inom 72 timmar.",
            response2Title: "Ojdå! Ditt meddelande kunde inte skickas",
            response2Description: "Testa igen, eller kontakta oss direkt på afsdfasfad.com om felet skulle kvarstå",
            
          },
          orders:{

          },
          footer:{
            title:"Var den första att veta",
            description: "Anmäl dig till vårt nyhetsbrev och få exklusiva nyheter och specialerbjudanden direkt i din inkorg. Missa inte chansen att vara först med att ta del av allt nytt!",
            navigation: "Navigation",
            navigationHome: "Hem",
            links: "Länkar",
            contact: "Kontakt",
            send: "Skicka",
            sending: "Skickar"
          },
          cookies:{
            title:"Vi värdesätter din sekretess",
            description:"Vi använder cookies för att förbättra din webbupplevelse, som att komma ihåg ditt föredragna språk! Genom att klikca på 'Acceptera Alla', accepterar du all användning av cookies.",
            neka : "Neka Alla",
            readMore: "Läs mer",
            acceptera : "Acceptera Alla",
            taBort : "Radera Alla"
          },
          orders:{
            title:"Företag eller förening?",
            description:"Vi tar emot beställningar från både företag och föreningar",
            section1Tit:"Eget tryck",
            section1Desc:"Vi erbjuder eget tryck på flaskor till både företag och föreningar. Vi printar i Sverige, vilket innebär kort leveranstid.",
            section2Tit:"Återförsäljare",
            section2Desc:"Vi säljer flaskor till lokala återförsäljare med vårt egna varumärke. Vill ni ha in våra flaskor i ert sortimenent?"
          }
        }
      }
    }
  });
