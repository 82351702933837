import { useRef, useState, useEffect } from "react";
import emailjs from '@emailjs/browser';

const FormContainer  = ({language}) => {
    const form = useRef();
    const [sending, setSending] = useState(false);
    const [successfulMail, setSuccessfulMail] = useState(false);
    const [sentMail, setSentMail] = useState(false);
    const [ellipsis, setEllipsis] = useState('.');

    console.log('Contact')
    /*
    * Function to display the loading text when form is being sent
    */
    

    /*
    * Function to send the mail to the contact form
    */

    const sendEmail = (e) => {
        
      e.preventDefault();
      setSending(true);
      emailjs
        .sendForm('service_8f4gbwm', 'template_v0gulho', form.current, {
          publicKey: 'yCFyktyLQ4BJYCRE_',
        })
        .then(
          () => {
            setSending(false);
            if (form.current) {
                form.current.reset();   
            }
            setSentMail(true)
            setSuccessfulMail(true);
          },
          (error) => {
            setSending(false);
            setSuccessfulMail(false)
            console.log('FAILED...', error.text);
          },
        );
    };

    return(

        <div className="form-wrapper">
            <div className="form-container">
                <form ref={form} id="contact-form" onSubmit={sendEmail}>
                    <div className="form-row">
                        <div className={sentMail ? "form-cell2" : "form-cell"}>
                            <label>{language("contact.name")}</label>
                            <input className="emailInput" type="text" name="user_name" placeholder={language("contact.nameExample")} required style={{backgroundColor:"#F2F2F2", color:"black"}}/>
                        </div>
                        <div className={sentMail ? "form-cell2" : "form-cell"}>
                            <label>{language("contact.email")}</label>
                            <input type="email" className="emailInput" name="user_email" placeholder={language("contact.emailExample")} required style={{backgroundColor:"#F2F2F2", color:"black"}}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-cell">
                            <label>{language("contact.message")}</label>
                            <textarea name="message" className='textInput' placeholder={language("contact.messageExample")}/>
                        </div>
                    </div>
                    <input type="submit" className="submitButton" value={sending ? `${language("contact.sending")}${ellipsis}` : language("contact.sendMessage")} />
                </form>
                {sentMail && <div className="confirmationWindow">{successfulMail ? (
                            <div>
                                <h2>{language("contact.response1Title")}</h2>
                                <p style={{color:"gray"}}>{language("contact.response1Description")}</p>
                            </div>
                        ) : (
                            <div>
                                <h2>{language("contact.response2Title")}</h2>
                                <p>{language("contact.response2Description")}</p>
                            </div>
                        )}
                    <button className='closeButton' onClick={() => setSentMail(false)}>
                        {language("close")}
                    </button>
                </div>}
            </div>
        </div>

    )
}


export default FormContainer;