import './ContactPage.css'
import { memo } from 'react';
import FormContainer from "./components/FormContainer";

/**
 * 
 * @param {updateNav} Whether the nav should be white or transparent
 * @param {updateFooter} Whether the footer includes the contact form or not
 * @returns 
 */

const ContactPage = memo(function Contact({ updateFooter, language}) {

    updateFooter(false);


    return(
        <div className="contact-container">
            <div className='max-container'>
                <div className="contact-header">
                    <h1>
                        {language("contact.title")}
                    </h1>
                    <div className="contact-header-text">

                    </div>
                    <FormContainer language={language}/>
                </div>
            </div>
        </div>
    )
})

export default ContactPage;