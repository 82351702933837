import React, { useEffect, useState } from 'react';

import './WrongUrl.css';



export default function WrongUrl({ updateFooter , language}) {

    updateFooter(false);
    return (
    <div className='wrongUrl'>
        <div className='wrongUrlContainer'>
            <h2>
                {language('wrongurl.title')}
            </h2>
            <p>
            {language('wrongurl.description')}
            </p>
        </div>
    </div>
    )
}