import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './ProductContainer.css';

const ProductContainer = ({ title, comment, children }) => {
    const navigate = useNavigate();
    const slideContainerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [containerHasOverflow, setContainerHasOverflow] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (children) {
            setLoading(false);
        }
        console.log(children);
    }, [children]);

    const checkOverflow = () => {
        const container = slideContainerRef.current;
        if (container) {
            const containerScrollWidth = container.scrollWidth;
            const containerClientWidth = container.clientWidth;
            console.log('Scroll Width:', containerScrollWidth, 'Client Width:', containerClientWidth);
            setContainerHasOverflow(containerScrollWidth > containerClientWidth);
            setScrollPosition(container.scrollLeft);
            setShowButtons(containerClientWidth < containerScrollWidth);
        }
        console.log(container)
    };

    const handleScroll = (direction) => {
        const container = slideContainerRef.current;
        const scrollAmount = 300; // Adjust this value for how much you want to scroll with each click

        if (direction === 'left') {
            container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
        } else if (direction === 'right') {
            container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }

        setTimeout(() => {
            setScrollPosition(container.scrollLeft);
        }, 300); // Adjust this timeout based on the smoothness of the scroll
    };

    const handleUserScroll = () => {
        const container = slideContainerRef.current;
        setScrollPosition(container.scrollLeft);
        setShowButtons(container.scrollLeft > 0 || container.scrollLeft < container.scrollWidth - container.clientWidth);
    };

    useEffect(() => {
        setTimeout(checkOverflow, 1000);
        window.addEventListener('resize', checkOverflow);
        const container = slideContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleUserScroll);
        }

        return () => {
            window.removeEventListener('resize', checkOverflow);
            if (container) {
                container.removeEventListener('scroll', handleUserScroll);
            }
        };
    }, []);

    return (
        <div className='slideOuterContainer' style={{ position: 'relative', width: '100%' }}>
            <div className='outerBox' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ flexDirection: 'column', textAlign: 'center' }}>
                <h3>{title}</h3>
                </div>

                <div style={{ display: 'flex', gap: '10px' }}>
                {containerHasOverflow && (
                    showButtons && scrollPosition > 0 ? (
                    <img src='/arrowLeftActive.png' onClick={() => handleScroll('left')} />
                    ) : (
                    <img src='/arrowLeftInactive.png' />
                    )
                )}

                {containerHasOverflow && (
                    showButtons && scrollPosition < slideContainerRef.current.scrollWidth - slideContainerRef.current.clientWidth ? (
                    <img src='/arrowRightActive.png' onClick={() => handleScroll('right')} />
                    ) : (
                    <img src='/arrowRightInactive.png' />
                    )
                )}
                </div>
            </div>
            <div style={{width:'100%'}}>
            <p style={{ marginTop: '0px', textAlign: 'left' }}>{comment}</p>
            </div>


            </div>

            <div
                className='slideContainer'
                ref={slideContainerRef}
                style={{

                    display: 'block',
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    scrollBehavior: 'smooth',
                }}
            >
                {loading ? (
                    <Skeleton count={10} height={200} />
                ) : (
                    children
                )}
            </div>
        </div>
    );
};

export default ProductContainer;
