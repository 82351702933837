import React, { useEffect, useState } from 'react';
import { getProducts, createCheckout } from './shopifyService';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const productData = await getProducts();
      setProducts(productData);
    };

    fetchProducts();
  }, []);

  const handleAddToCart = (variantId) => {
    console.log(cart)
    setCart([...cart, { variantId, quantity: 1 }]);
  };

  const handleCheckout = async () => {

    console.log(cart)
    const checkout = await createCheckout(cart.map(item => ({ variantId: item.variantId, quantity: item.quantity })));
    window.location.href = checkout.webUrl;
  };

  return (
    <div>
      <h1>Products</h1>
      <ul>
        {products.map(({ node }) => (
          <li key={node.id}>
            <h2>{node.title}</h2>
            <p>{node.description}</p>
            <img style={{height:'200px'}} src={node.images.edges[0]?.node.originalSrc} alt={node.images.edges[0]?.node.altText} />
            <ul>
              {node.variants.edges.map(({ node: variant }) => (
                <li key={variant.id}>
                  {variant.title}: {variant.priceV2.amount} {variant.priceV2.currencyCode}
                  <button onClick={() => handleAddToCart(variant.id)}>Add to Cart</button>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
      <button onClick={handleCheckout} disabled={cart.length === 0}>Go to Checkout</button>
    </div>
  );
};

export default ProductList;