import "./OrdersPage.css"
import { Link } from "react-router-dom";
import { memo, useEffect } from 'react';
import Header from './components/Header';
import ContentContainer1 from "./components/ContentContainer1";
import ContentContainer2 from "./components/ContentContainer2";

/**
 * 
 * @param {updateNav} Whether the nav should be white or transparent
 * @param {updateFooter} Whether the footer includes the contact form or not
 * @returns 
 */

const Orders = memo(function Orders({ updateFooter, language, routerLang}) {
    
    useEffect(() => {

        updateFooter(true);
    
        // Cleanup function to reset the state if needed when component unmounts
        return () => {
          updateFooter(false);
        };
      }, [ updateFooter]);

    return(
        <div className="order-container">
                <Header language={language}/>
                <ContentContainer1 language={language} routerLang={routerLang}/>
                <div className="borderBottom" style={{marginLeft:"30px", marginRight:"30px"}}/>
                <ContentContainer2 language={language} routerLang={routerLang}/>

        </div>
    );
})

export default Orders;

