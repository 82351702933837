import { Link } from "react-router-dom";

const ContentContainer2  = ({language, routerLang}) => {
    
    return(
        <div className="gridContainer" id="gridID">
            <div className="gridImageContainer2" >
                <img src="/bxhaled10.jpg"/>
            </div>
            <div className="gridInfoContainer" >
                <h3>{language('orders.section2Tit')}</h3>
                <p>
                    {language('orders.section2Desc')}
                </p>
                <Link className="linken" to={`/${routerLang}/contact`} >{language('navbar.contact')}</Link>                     
            </div>
        </div>
    )

}


export default ContentContainer2;
