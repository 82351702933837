import emailjs from '@emailjs/browser';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { memo } from 'react';

/**
 * 
 * @param {dynamicFooter} whether or not the footer should include the contact form
 * @returns 
 */

const Footer = memo(function Footer({ dynamicFooter, language, routerLang, cookieVar }) {
    const newForm = useRef();
    const [sending, setSending] = useState(false);
    const [ellipsis, setEllipsis] = useState('.');
    const [successfulMail, setSuccessfulMail] = useState(false);

    /*
    * Function to display the loading text when form is being sent
    */
    useEffect(() => {
        let intervalId;
        if (sending) {
            intervalId = setInterval(() => {
                setEllipsis((prevEllipsis) => {
                    switch (prevEllipsis) {
                        case '.':
                            return '..';
                        case '..':
                            return '...';
                        default:
                            return '.';
                    }
                });
            }, 500); // Change the interval as needed
        } else {
            clearInterval(intervalId);
            setEllipsis('.');
        }

        return () => clearInterval(intervalId);
    }, [sending]);

    const setCookies = () => {
        cookieVar(false);
    }
    /*
    * Function to send the mail to the contact form
    */
    const sendEmail = (e) => {
        e.preventDefault();
        setSending(true);

        emailjs
            .sendForm('service_8f4gbwm', 'template_tcwayxv', newForm.current, {
                publicKey: 'yCFyktyLQ4BJYCRE_',
            })
            .then(
                () => {
                    setSending(false);
                    if (newForm.current) {
                        newForm.current.reset();
                    }
                    setSuccessfulMail(true)
                    setTimeout(() => {
                        setSuccessfulMail(false)
                    }, 10000);

                },
                (error) => {
                    setSending(false);
                },
            );
    };

    return (
        <>
            <div className='footer-container'>
                {dynamicFooter ? (<div className='subLetterContainer' id='subletter' style={{zIndex:10}}>
                    {successfulMail ? (
                        <div className='thankyou'><img src='check.png' style={{ maxWidth: "50px" }} />
                            <h3 style={{ marginTop: "0", marginBottom: "0" }}>{language("thankYou")}</h3>
                            <p style={{ color: "gray", marginTop: "10px", marginBottom: "20px", marginLeft: "20%", marginRight: "20%" }}>Du har börjat prenumera på vårt nyhetsbrev!</p>
                        </div>
                    ) : (
                        <>
                            <h2 style={{ marginBottom: "0" }}>{language('footer.title')}</h2>
                            <p style={{ color: "gray", marginTop: "10px", marginBottom: "20px" }}>{language('footer.description')}</p>
                            <form ref={newForm} onSubmit={sendEmail} className='subLetter'>
                                <input type="email" id="emailInput" name="user_email" className='emailInput' placeholder={language("contact.emailExample")} style={{ backgroundColor: "#FFFFFF" }} required />
                                <input type="submit" className='submitFormButton' value={sending ? `${language("footer.sending")}${ellipsis}` : language("footer.send")} />
                            </form>
                        </>
                    )}

                </div>
                ) : (<div />)}
                <div className='max-container' style={{maxWidth:"1800px"}}>
                <div className='content-container'>
                    <div>
                        <img className="logo" src='/logoWhite.png' />
                    </div>
                    <div className='content-row-container' style={{ gridTemplateColumns: dynamicFooter ? '1fr 1fr 1fr' : '1fr 1fr 2fr' }}>
                        <div className='content-row'>
                            <div className='content-title'>
                                {language('footer.navigation')}
                            </div>
                            <div className='content-link-container'>
                                <div className='content-link'>
                                    <Link className="link" to={`/${routerLang}`}>{language('footer.navigationHome')}</Link>
                                </div>
                                <div className='content-link'>
                                    <Link className="link" to={`/${routerLang}/orders`}>{language('navbar.orders')}</Link>
                                </div>
                                <div className='content-link'>
                                    <Link className="link" to={`/${routerLang}/contact`}>{language('navbar.contact')}</Link>
                                </div>
                            </div>
                        </div>
                        <div className='content-row'>
                            <div className='content-title'>
                                {language('footer.links')}
                            </div>
                            <div className='content-link-container'>
                                <div className='content-link'>
                                    <a className='link' href='https://www.amazon.se/stores/bXhaled/page/56BB76A4-3D26-4277-9C5D-7F1ABB2FD2F8?ref_=ast_bln' target="_blank">Amazon Store</a>
                                </div>
                                <div className='content-link'>
                                    <a className='link' href='https://www.instagram.com/bxhaled/' target="_blank">Instagram</a>
                                </div>
                                <div className='content-link'>
                                    <a className='link' href='https://www.facebook.com/bXhaled/' target="_blank">Facebook</a>
                                </div>
                            </div>
                        </div>
                        <div className='content-row'>
                            {!dynamicFooter ? (
                                <>
                                    <div className='content-title' style={{ fontSize: "25px" }}>
                                        NYHETSBREV
                                    </div>
                                    <div className='content-link-container'>
                                        <div className='newsInfo'>
                                            <p>Få nyheter och erbjudande</p>
                                        </div>
                                        <form ref={newForm} onSubmit={sendEmail} className='newsForm'>
                                            <input type="email" name="user_email" className='emailInput' required />
                                            {successfulMail ? (
                                                <div className='confirmForm'><p>Skickat</p></div>
                                            ) : (
                                                <input type="submit" id="submitNews" className='submitFormButton' value={sending ? `Skickar${ellipsis}` : "Skicka"} />
                                            )}
                                        </form>
                                    </div>
                                </>
                            ) : (
                                <div className='content-row'>
                                    <div className='content-title'>
                                        {language('footer.contact')}
                                    </div>
                                    <div className='content-link'>
                                        contact@bxhaled.com
                                    </div>
                                    <div className='content-link'>
                                        Svampvägen 38<br />
                                        Boden <br />
                                        Sweden<br />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='borderFooter'></div>
                <div className='borderLinksContainer'>
                    &copy; 2024 | bXhaled
                    <div className='borderLinks'>
                        <Link to={`/${routerLang}/privacy`}>Privacy & Cookie Policy</Link>
                        <a onClick={setCookies}>Cookie settings</a>
                    </div>
                </div>
            </div>
            </div>
        </>
    )

})

export default Footer;