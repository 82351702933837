const BottleSpecificationContainer  = ({language}) => {

    return(
        <div className='bottleSpecification'>
            <div className='max-container'>
                <div style={{paddingTop:"0px", marginLeft:"10vw", marginRight:"20vw",display:"grid", gridTemplateColumns:"1fr 1fr"}}>
                    <div>
                        <div style={{display:"grid", gridTemplateColumns:"10px auto", alignItems:"center"}}>
                            <p>{language('home.desc.volym')}</p>
                            <p className="specificationP" >{language('home.desc.volym2')}</p>
                        </div>
                        <div className="borderBottom" id='borderSpec'/>

                        <div style={{display:"grid", gridTemplateColumns:"10px auto", alignItems:"center" }}>
                            <p>{language('home.desc.material')}</p>
                            <p className="specificationP" >{language('home.desc.material2')}</p>
                        </div>
                        <div className="borderBottom" id='borderSpec'/>

                        <div style={{display:"grid", gridTemplateColumns:"10px auto", alignItems:"center"}}>
                            <p>{language('home.desc.dimensioner')}</p>
                            <p className="specificationP">{language('home.desc.dimensioner2')}</p>
                        </div>
                        <div className="borderBottom" id='borderSpec'/>

                        <div style={{display:"grid", gridTemplateColumns:"10px auto", alignItems:"center"}}>
                            <p>{language('home.desc.annat')}</p>
                            <p className="specificationP" >{language('home.desc.annat2')}</p>
                        </div>
                        <div style={{paddingTop:"50px", paddingBottom:'40px'}}>
                            <img src='/image 8.png' style={{height:"70px", paddingRight:"10px"}}></img><img src='/image 7.png' style={{height:"60px"}}></img>
                        </div>
                    </div>
                    <div>
                        <div style={{width:"100%"}} className='rodflaska'>
                        <img src='/rodflaskapng.png'></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default BottleSpecificationContainer;