import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { getProductDetail } from '../../backend/shopify/shopifyService';

const ProductContext = createContext();

export const useProductContext = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [variantImages, setVariantImages] = useState({});

    const associateImagesWithVariants = useCallback((images, variants) => {
        const variantImagesMap = {};
    
        variants.forEach(variant => {
            const variantId = variant.node.id;
            const variantTitle = variant.node.title;
    
            // Ensure an entry for this variant ID exists in the map
            if (!variantImagesMap[variantId]) {
                variantImagesMap[variantId] = [];
            }
    
            // Preload the image directly linked to the variant
            if (variant.node.image) {
                const variantImage = { node: variant.node.image };
                variantImagesMap[variantId].push(variantImage);
                // Preload the image
                const img = new Image();
                img.src = variant.node.image.originalSrc;
            }
    
            // Find and associate related images by matching the altText with the variant title
            const relatedImages = images.filter(image =>
                image.node.altText && image.node.altText.includes(variantTitle)
            );
    
            // Preload related images
            relatedImages.forEach(image => {
                const img = new Image();
                img.src = image.node.originalSrc;
            });
    
            variantImagesMap[variantId] = [...variantImagesMap[variantId], ...relatedImages];
        });
    
        // Update the state with the newly mapped and preloaded images
        setVariantImages(prevState => ({
            ...prevState,
            ...variantImagesMap
        }));
    }, []);
    
    

    useEffect(() => {
        const fetchProduct = async () => {
            const productData = await getProductDetail('gid://shopify/Product/9090614067526');
            setProducts(productData);

            const variants = productData.variants.edges;
            const images = productData.images.edges;

            associateImagesWithVariants(images, variants);
        };

        fetchProduct();
    }, [associateImagesWithVariants]);

    const providerValue = useMemo(() => ({
        products,
        variantImages,
    }), [products, variantImages]);

    return (
        <ProductContext.Provider value={providerValue}>
            {children}
        </ProductContext.Provider>
    );
};
