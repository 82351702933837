import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

const useLanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const history = useNavigate();
  const location = useLocation();
  const [cookies, setCookie] = useCookies();

  const changeLanguageAndRedirect = (languageToUse, ) => {
    console.log(languageToUse)
    if (languageToUse === "sv" || languageToUse === "SV") {
      console.log('if')
      i18n.changeLanguage("sv");
      history("/sv" + location.pathname.slice(3)); // Redirect with the new language
    } else {
      console.log('else')
      i18n.changeLanguage("en");
      history("/en" + location.pathname.slice(3)); // Fallback to English if the language is unsupported
    }

    console.log('hej', languageToUse)
    // Update the cookie for language preference if needed
    if(cookies.bxh_appr == '1'){
      setCookie('bxh_lang', languageToUse, { path: '/' });
    }
  
  };

  return { changeLanguageAndRedirect };
};

export default useLanguageSwitcher;