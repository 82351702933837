import { Link } from "react-router-dom";

const ContentContainer1  = ({language, routerLang}) => {
    
    return(
        <div className="gridContainer">
            <div className="gridInfoContainer">
                <h3>{language('orders.section1Tit')}</h3>
                <p>
                    {language('orders.section1Desc')}
                </p>
                <Link className="linken" to={`/${routerLang}/contact`}>{language('navbar.contact')}</Link>                     
            </div>
            <div className="gridImageContainer1">
                <img src="/gridImage.png"/>
            </div>
        </div>
    )

}


export default ContentContainer1;