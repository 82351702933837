import { Link } from "react-router-dom";

const OurStoryContainer  = ({language, routerLang}) => { 
    
    return(
        <div className='ourJourney'>
            <div className='max-container' style={{maxWidth:"1800px"}}>
                <div className='gridContain2'>
                    <div className="gridImageContainer3">
                        <img src="/headerbild.png" style={{borderRadius:"0", objectFit:"contain", transform: "scaleX(-1)"}}/>
                    </div>
                    <div className="gridInfoContainerss" >
                        <h2 style={{color:"white"}}>{language('home.story.title')}</h2>
                        <p  style={{color:"white", paddingTop:"50px"}}>{language('home.story.description')}</p>
                        <p  style={{color:"white"}}>{language('home.story.description2')}</p>
                        <div style={{height:'40px'}}></div>
                        <Link to={`/${routerLang}/contact`} className='buyButton' id='contactButton'>{language('navbar.contact')}</Link>  
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurStoryContainer;