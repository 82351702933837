import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './ProductShell.css';

const ProductShell = ({ imageUrl, onClick, title, description, price }) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        // Function to check if the image is cached
        const checkImageCache = (url) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => resolve(true);  // Image is loaded
                img.onerror = () => resolve(false); // Image failed to load
                img.src = url;
            });
        };

        // Check if image is cached
        const loadImage = async () => {
            const cached = await checkImageCache(imageUrl);
            setImageLoaded(cached);
        };

        // Reset state and check image cache
        setImageLoaded(false);
        loadImage();
    }, [imageUrl]);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        <div className='productImageShellContainer' onClick={onClick}>
            {!imageLoaded && (
                <Skeleton height={400} width={320} />
            )}
            <img
                className='productImageShell'
                src={imageUrl}
                onLoad={handleImageLoad}
                style={{ display: imageLoaded ? 'block' : 'none' }}
                alt={title}
                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
            />
            <h4 style={{ marginTop: '5px', marginBottom: '0' }}>{title}</h4>
            <p style={{ marginTop: '5px', marginBottom: '0' }}>{description}</p>
            <h4 style={{ marginTop: '0' }}>{parseInt(price)} kr</h4>
        </div>
    );
};

export default ProductShell;
