import React, { useEffect, useState, useRef } from 'react';
import './CookieConsentForm.css';
import { Link } from 'react-router-dom';
import {useCookies} from 'react-cookie';



/**
 * 
 * @param {dynamicFooter} whether or not the footer should include the contact form
 * @returns 
 */

export default function CookieConsentForm({setHasCookies, language, routerLang}) {

  const [cookies, setCookie, removeCookie] = useCookies();

  const expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 1);
  
  const acceptCookies = () => {

    setCookie('bxh_appr','1', { expires: expirationDate, path: '/' })
    setHasCookies(true)
  }

  const rejectCookies = () => {
    setHasCookies(true);
  }

  const removeCookies = () => {
    removeCookie('bxh_appr')
    removeCookie('bxh_lang')
    setHasCookies(true);
  }
    
  return(
    <div className='cookieConsentContainer'>
      <div className='cookieInfo'><h3>
      {language('cookies.title')}
        
        </h3>
        <p>
        {language('cookies.description')}
        </p>
        <Link to={`/${routerLang}/privacy`} onClick={removeCookies}>{language('cookies.readMore')}</Link>
      </div>
      <div className='cookieButtons'>
        {cookies.bxh_appr ? (
          <div className='rejectCookies' onClick={removeCookies}>
            <p>
              {language('cookies.taBort')}
            </p>
          </div>
        ) : (
          <div className='rejectCookies' onClick={rejectCookies}>
            <p>
            {language('cookies.neka')}
            </p>
          </div>
        )}
        <div className='acceptCookies' onClick={acceptCookies}>
          <p>
          {language('cookies.acceptera')}
          </p>
        </div>

      </div>

    </div>
  
  );
}