import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './Navbar.css';
import { AnimatePresence, motion, useScroll, useMotionValueEvent } from 'framer-motion';
import { Link, NavLink } from 'react-router-dom';
import { memo } from 'react';


const Navbar = memo(function Navbar({language, routerLang, chosenLanguage }) {
  // Define additional styles for pushing down navbar

  console.log('nav')
    const {scrollY} = useScroll();
    const [hidden, setHidden] = useState(false);
    const [navbars, setNavbar] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    var navVersion = true;

    const defaultStyle = {
        color: navbars ? "white" : "black", // Change color based on navbars state
    };

    const currentStyle = {
        ...defaultStyle,
        transition: "color 0.35s ease, background-color 0.35s ease" // Smooth transition for color and background color change
    };
    const navStyle = {
        transition: "background-color 0.35s ease" // Smooth transition for background color change
    };

    

    const menuVars = {
        initial: {
          translateX: '100%', // Menu starts off-screen to the right
          opacity: 0,        // Menu is invisible initially
        },
        animate: {
          translateX: 0,     // Menu slides in to its position
          opacity: 1,        // Menu becomes visible
          transition: {
            duration: 0.15,    // Slightly longer duration for smoother animation
            ease: [0.12, 0, 0.1, 0],  // Ease-in-out curve for smooth transitions
          },
        },
        exit: {
          translateX: '100%', // Menu slides back off-screen to the right
          opacity: 0,        // Menu fades out
          transition: {
            duration: 0.4,    // Same duration for consistency
            ease: [0.22, 1, 0.36, 1],  // Smooth exit transition
          },
        },
      };

    useMotionValueEvent(scrollY, "change", (latest) => {
        const prev = scrollY.getPrevious()
        if (latest>prev && latest > 100){
            setHidden(true)
        }
        else{
            setHidden(false)
        }
    });

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
          if (window.innerWidth > 800) {
            setMenuOpen(false);
          }
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    useEffect(() => {
        if (menuOpen){
            document.body.style.overflow = 'hidden';
        }
        else{
            document.body.style.overflow = 'visible';
        }
    }, [menuOpen]); // State is added as a dependency



    
    return <motion.nav
    variants={{
        visible: {y:0},
        hidden: {y:"-100%"},
    }}
    animate={hidden ? "hidden" : "visible"}
    transition={{duration: 0.15, ease: "easeInOut"}}
    className={navbars ? 'nav nav2' : 'nav'}
    style={navStyle}
    >

        <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <img className="menuButton" src={navbars ? (menuOpen ? "/close.png" : "/hamburger-menu-white.png") : (menuOpen ? "/close.png" : "/hamburger-menu-green.png")} alt="Menu Button"></img>

        </div>
        <div className={menuOpen ? "activeBackground" : "nonActiveBackground"} onClick={() => setMenuOpen(false)}/>
        <AnimatePresence>   
            {menuOpen ? (
                <motion.div  
                    variants={menuVars}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    className="open"
                    style={{ transformOrigin: "right" }}
                >
                        <img src="/logoColor.png" onClick={() => setMenuOpen(false)} alt="Logo" style={{height:'30px', position:'absolute', top:'0', paddingTop:'25px', paddingLeft:'25px'}}/>
                        <ul> 
                            <li>
                                <Link to={`/${routerLang}/`} className='containerLink' id='hamNavLink' onClick={() => setMenuOpen(false)}>
                                    {language('navbar.home')}
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${routerLang}/contact`} className='containerLink' id='hamNavLink' onClick={() => setMenuOpen(false)}>
                                    {language('navbar.contact')}
                                </Link>
                            </li>
                            <li>
                                <Link to={`/${routerLang}/orders`} className='containerLink' id='hamNavLink' onClick={() => setMenuOpen(false)}>
                                    {language('navbar.orders')}
                                </Link>
                            </li>

                            <li>
                                <a href="https://www.amazon.se/bXhaled-Vattenflaska-Luktreducerande-L%C3%A4ckages%C3%A4ker-Sportflaska/dp/B0CY5JKXTZ?ref_=ast_sto_dp&th=1&psc=1" target="_blank" className='containerLink' id='hamNavLink' onClick={() => setMenuOpen(false)}>
                                    {language('buyNow')}
                                </a>
                            </li>
                        
                        </ul>

                </motion.div>
            ) : (
                <div style={{ maxWidth: '2000px', margin: '0 auto', width: '100%' }}>
                <ul className="close" style={{ display: 'flex', alignItems: 'center'}}>
                  {/* Logo aligned to the left */}
                  <Link to={`/${routerLang}`} className="siteIcon">
                    <img src="/logoColor.png" onClick={() => setMenuOpen(false)} alt="Logo" />
                  </Link>
              
                  {/* Centered navigation links */}
                  <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                    <li>
                      <div className="containerLink" id="navlinked">
                        <Link
                          to={`/${routerLang}/orders`}
                          className="links"
                          style={currentStyle}
                          onClick={() => setMenuOpen(false)}
                        >
                          {language('navbar.orders')}
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="containerLink" id="navlinked">
                        <Link
                          to={`/${routerLang}/contact`}
                          className="links"
                          style={currentStyle}
                          onClick={() => setMenuOpen(false)}
                        >
                          {language('navbar.contact')}
                        </Link>
                      </div>
                    </li>
                  </div>
              
                  {/* Buy button aligned to the right */}
                  <li>
                    <a
                      href="https://www.amazon.se/bXhaled-Vattenflaska-Luktreducerande-L%C3%A4ckages%C3%A4ker-Sportflaska/dp/B0CY5JKXTZ?ref_=ast_sto_dp&th=1&psc=1"
                      target="_blank"
                      rel="noreferrer"
                      className="buyButton"
                      style={{ marginRight: '5vw' }}
                    >
                      {language('buyNow')}
                    </a>
                  </li>
                </ul>
              </div>

            )}
        </AnimatePresence>

    </motion.nav>
})

export default Navbar;

