import { useState, useEffect, memo } from "react";
import { Blurhash } from "react-blurhash";

const Header = ({ language }) => {
  const [isPhone, setIsPhone] = useState(true);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsPhone(true);
      } else {
        setIsPhone(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call handler once to set initial state

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleImageLoad = () => {
    setImgLoaded(true);
  };

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  return (
    <div className="header" style={{ backgroundColor: "#292929" }}>
      <div className="image-container">
        {isPhone ? (
          <>
            {!imgLoaded && (
              <Blurhash
                hash="L6A0UQ~94Uxt-n$%Fy9aO@D+0fXU"
                width="100%"
                height="100%"
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
            )}
            <img
              src="/bxhaled4.jpg"
              alt="Header Image"
              className="header-image"
              onLoad={handleImageLoad}
              style={{ display: imgLoaded ? 'block' : 'none' }}
            />
            <div className="overlay" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)', width: "100%" }}></div>
          </>
        ) : (
          <>
            {!videoLoaded && (
              <Blurhash
                hash="L6A0UQ~94Uxt-n$%Fy9aO@D+0fXU"
                width="100%"
                height="100%"
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
            )}
            <video
              alt="Header Video"
              playsInline
              muted
              loop
              controls={false}
              autoPlay
              className="header-video"
              onLoadedData={handleVideoLoad}
              style={{ display: videoLoaded ? 'block' : 'none' }}
            >
              <source src="/headermov.mov" type="video/mp4" poster="headermov.png" />
            </video>
          </>
        )}
      </div>
      <div className="info-container">
        <div className="headerLeft">
          <h1>
            {language('home.header.intro')}
          </h1>
          <br />
          <p style={{ color: 'white' }}>
            {language('home.header.description')}
          </p>
          <a href='https://www.amazon.se/stores/bXhaled/page/56BB76A4-3D26-4277-9C5D-7F1ABB2FD2F8?ref_=ast_bln' target="_blank" className='buyButton' style={{ marginLeft: "0px" }}>{language('buyNow')}</a>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);